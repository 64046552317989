var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "optional-items-component" },
    [
      !_vm.loaded
        ? _c(
            "b-row",
            {
              staticClass: "pt-4 mt-4",
              staticStyle: { "min-height": "140px" },
            },
            [_c("ct-centered-spinner")],
            1
          )
        : _vm.showOptionalItems
        ? [
            _c("h3", [_vm._v("\n      Optional Items\n    ")]),
            _c(
              "div",
              { staticClass: "my-4" },
              [
                _vm._l(_vm.optionalCategoryProducts, function (item, index) {
                  return [
                    _vm.showSubProducts(item)
                      ? [
                          _c(
                            "b-checkbox",
                            {
                              key: item.displayName,
                              staticClass: "mt-2",
                              attrs: { switch: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.categoryWithSubProductSelected(
                                    $event,
                                    index
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.displayNameAndPrice(item, true)) +
                                  "\n\n            "
                              ),
                              item.description
                                ? _c("fa-icon", {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.right",
                                        value: item.description,
                                        expression: "item.description",
                                        modifiers: { hover: true, right: true },
                                      },
                                    ],
                                    staticClass:
                                      "question-circle pl-1 help-text",
                                    attrs: {
                                      icon: "question-circle",
                                      size: "1x",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              key: index,
                              staticClass: "ml-4",
                              staticStyle: { display: "none" },
                              attrs: { id: `subProducts-${index}` },
                            },
                            _vm._l(
                              _vm.sortedProducts(
                                item.displayName,
                                item.products
                              ),
                              function (subItem) {
                                return _c(
                                  "b-checkbox",
                                  {
                                    key: subItem.id,
                                    staticClass: "mt-2",
                                    attrs: {
                                      checked: _vm.isIncluded(subItem),
                                      disabled:
                                        _vm.optionalItemDisabled(subItem),
                                      switch: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.selectItem(subItem, $event)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.displayNameAndPrice(subItem, true)
                                        ) +
                                        "\n\n              "
                                    ),
                                    subItem.description
                                      ? _c("fa-icon", {
                                          directives: [
                                            {
                                              name: "b-popover",
                                              rawName:
                                                "v-b-popover.hover.right",
                                              value: subItem.description,
                                              expression: "subItem.description",
                                              modifiers: {
                                                hover: true,
                                                right: true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "question-circle pl-1 help-text",
                                          attrs: {
                                            icon: "question-circle",
                                            size: "1x",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ]
                      : item.optionalFilingMethods
                      ? [
                          item.optionalFilingMethods
                            ? _c(
                                "b-checkbox",
                                {
                                  key: item.displayName,
                                  staticClass: "mt-2",
                                  style: {
                                    display: _vm.showOptionalFilingMethods(item)
                                      ? "block"
                                      : "none",
                                  },
                                  attrs: { switch: "" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.additionalCategorySelected(
                                        item,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.displayNameAndPrice(item, true)
                                      ) +
                                      "\n\n            "
                                  ),
                                  item.description
                                    ? _c("fa-icon", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.hover.right",
                                            value: item.description,
                                            expression: "item.description",
                                            modifiers: {
                                              hover: true,
                                              right: true,
                                            },
                                          },
                                        ],
                                        staticClass:
                                          "question-circle pl-1 help-text",
                                        attrs: {
                                          icon: "question-circle",
                                          size: "1x",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              key: item.filing_name,
                              staticStyle: {
                                display: "none",
                                "margin-left": "4rem",
                              },
                              attrs: { id: "disclaimer" },
                            },
                            [
                              _c("hr"),
                              _c("em", [
                                _c("small", [
                                  _c("b", [_vm._v(_vm._s(_vm.disclaimerText))]),
                                ]),
                              ]),
                              _c("hr"),
                            ]
                          ),
                          _vm.showOptionalFilingMethods(item)
                            ? _c(
                                "div",
                                {
                                  key: item.stateJurisdiction,
                                  staticStyle: {
                                    display: "none",
                                    "margin-left": "4rem",
                                  },
                                  attrs: { id: "localJurisdictionSelection" },
                                },
                                [
                                  _c("multi-select", {
                                    attrs: {
                                      multiple: true,
                                      placeholder: `Choose the cities / counties to file ${item.displayName}`,
                                      options: item.optionalFilingMethods,
                                      label: "local_jurisdiction",
                                      "track-by": "name",
                                    },
                                    on: {
                                      select: (option) =>
                                        _vm.selectedLocalJurisdiction(
                                          item,
                                          option
                                        ),
                                      remove: (option) =>
                                        _vm.removedLocalJurisdiction(
                                          item,
                                          option
                                        ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "option",
                                          fn: function (props) {
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    props.option
                                                      .local_jurisdiction +
                                                      " - $" +
                                                      (item.price +
                                                        props.option.cost)
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.localJurisdictionSelection,
                                      callback: function ($$v) {
                                        _vm.localJurisdictionSelection = $$v
                                      },
                                      expression: "localJurisdictionSelection",
                                    },
                                  }),
                                  _c("small", [
                                    _vm._v(
                                      "* If your city/county is not listed in the dropdown above, please email us so\n              we can determine if it can be added and facilitate the filing process for you."
                                    ),
                                  ]),
                                  _c("hr"),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm.showOptionalFilingMethods(item)
                      ? _c(
                          "b-checkbox",
                          {
                            key: index,
                            staticClass: "mt-2",
                            attrs: {
                              checked: _vm.isIncluded(item),
                              disabled: _vm.optionalItemDisabled(item),
                              switch: "",
                            },
                            on: {
                              change: function ($event) {
                                item.stateJurisdiction
                                  ? _vm.additionalCategorySelected(item, $event)
                                  : _vm.selectItem(item, $event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.displayNameAndPrice(item, true)) +
                                "\n\n          "
                            ),
                            item.description
                              ? _c("fa-icon", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.right",
                                      value: item.description,
                                      expression: "item.description",
                                      modifiers: { hover: true, right: true },
                                    },
                                  ],
                                  staticClass: "question-circle pl-1 help-text",
                                  attrs: {
                                    icon: "question-circle",
                                    size: "1x",
                                  },
                                })
                              : _vm._e(),
                            item.stateJurisdiction
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "none",
                                      "margin-left": "4rem",
                                    },
                                    attrs: { id: "disclaimer" },
                                  },
                                  [
                                    _c("hr"),
                                    _c("em", [
                                      _c("small", [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.disclaimerText)),
                                        ]),
                                      ]),
                                    ]),
                                    _c("hr"),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }